import React, { ReactNode, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Space, Table, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "fp/store/hooks";
import {
  moniepointAccounts,
  moniepointActive,
  moniepointCurrent,
  moniepointDisable,
} from "fp/store/slice/moniepointTask";
import dayjs from "dayjs";
import { Decimal } from "decimal.js";
import { CreateMoniepointBank } from "fp/components/moniepoint-bank/CreateMoniepointBank";
import { MoniepointTaskParams } from "fp/store/type";

export interface MoniepointTaskProps {}
export interface MoniepointTaskViewState {}

function formatNumberWithCommas(s: string): string {
  let [integer, decimal = ""] = s.split(".");

  const doSplit = (n: string, isInteger = true) => {
    if (n === "" || n === void 0) {
      return "";
    }

    let a: string[] = n.split("");

    if (isInteger) {
      a = a.reverse();
    }

    let str: string[] = [];

    for (let i = 0; i < a.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        str.push(",");
      }
      str.push(a[i]);
    }

    if (isInteger) {
      return str.reverse().join("");
    }

    return str.join("");
  };

  integer = doSplit(integer);
  decimal = doSplit(decimal, false);
  return integer + (decimal === "" ? "" : "." + decimal);
}

export function MoniepointTask(props: MoniepointTaskProps): ReactNode {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<MoniepointTaskParams | null>(null);
  const mp = useAppSelector((state) => state.moniepointBank);
  const dispatch = useAppDispatch();
  const { loading, accounts, current } = useAppSelector((state) => state.moniepointTask);

  // useEffect(() => {
  //   dispatch(moniepointAccounts());
  //   dispatch(moniepointCurrent());
  // }, []);

  useEffect(() => {
    if (!mp.loading.create || !mp.loading.update) {
      dispatch(moniepointAccounts());
      dispatch(moniepointCurrent());
    }
  }, [mp.loading.create, mp.loading.update]);

  const d = accounts.data.length > 0 ? accounts.data[0] : null;

  return (
    <>
      <Card
        title={
          <Row justify="space-between">
            <Col>Moniepoint支付账户管理</Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  setSelected(null);
                  setOpen(true);
                }}
              >
                新增支付账户
              </Button>
            </Col>
          </Row>
        }
      >
        <Table
          bordered
          size="small"
          rowKey="id"
          loading={loading.accounts || loading.current}
          dataSource={accounts.data}
          pagination={{
            pageSize: accounts.size,
            total: accounts.total,
            current: accounts.current,
          }}
          columns={[
            {
              dataIndex: "id",
              title: "ID",
              align: "center",
            },
            {
              dataIndex: "phoneNumber",
              title: "登录电话号码",
              align: "center",
            },
            {
              dataIndex: "loginUsername",
              title: "登录用户名",
              align: "center",
            },
            {
              dataIndex: "customerName",
              title: "账户名称",
              align: "center",
            },
            {
              dataIndex: "account",
              title: "银行账户",
              align: "center",
            },
            {
              dataIndex: "paymentCount",
              title: `支付总额${
                d !== null
                  ? `(${dayjs(d.paymentCountSince).format("DD/HH:mm")}~${dayjs(d.paymentCountUntil).format(
                      "DD/HH:mm"
                    )})`
                  : ""
              }`,
              align: "center",
              render(value, record, index) {
                var d = new Decimal(record.paymentCount).div(100);
                return formatNumberWithCommas(d.toString());
              },
            },
            {
              dataIndex: "id",
              title: "状态",
              align: "center",
              render(value, record, index) {
                if (current.includes(record.phoneNumber)) {
                  return <Tag color="success">已激活</Tag>;
                }
                return <Tag color="default">未激活</Tag>;
              },
            },
            {
              dataIndex: "id",
              title: "操作",
              align: "center",
              render(value, record, index) {
                const s = loading.active !== null && loading.active === record.phoneNumber;
                return (
                  <Space>
                    <Button
                      type="primary"
                      size="small"
                      danger={current.includes(record.phoneNumber)}
                      style={{ padding: 2 }}
                      disabled={s}
                      loading={s}
                      onClick={() =>
                        dispatch(
                          current.includes(record.phoneNumber)
                            ? moniepointDisable(record.phoneNumber)
                            : moniepointActive(record.phoneNumber)
                        )
                      }
                    >
                      {current.includes(record.phoneNumber) ? "禁用" : "激活"}
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      style={{ padding: 2 }}
                      disabled={mp.loading.update === record.bankModel.id}
                      loading={mp.loading.update === record.bankModel.id}
                      onClick={() => {
                        setSelected(record);
                        setOpen(true);
                      }}
                    >
                      编辑
                    </Button>
                  </Space>
                );
              },
            },
          ]}
        />
      </Card>
      <Modal
        title="Moniepoint账户信息"
        footer={null}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        destroyOnClose
        mask
        maskClosable
      >
        <CreateMoniepointBank edit={selected !== null} defaultValue={selected?.bankModel} />
      </Modal>
    </>
  );
}
